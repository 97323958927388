import { distinctUntilChanged } from 'rxjs/operators';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers/index';
import { getCurrencyState } from '../reducers/currency/currency.service';

@Pipe({
  name: 'currencySymbol',
  pure: false, // required to update the value when the subscription is resolved
  standalone: true,
})
export class CurrencySymbolPipe implements PipeTransform, OnDestroy {
  private value;
  private onCurrencyChange;

  public constructor(private store: Store<AppState>, private _ref: ChangeDetectorRef) {}

  public transform(amount: string | number): string {
    // if there is a subscription to onCurrencyChange, clean it
    this._dispose();

    this.onCurrencyChange = this.store
      .select(getCurrencyState)
      .pipe(distinctUntilChanged())
      .subscribe((currencySettings) => {
        this.value = currencySettings.symbol;
        this._ref.markForCheck();
      });

    return this.value;
  }

  /**
   * Clean any existing subscription to change events
   * @private
   */
  public _dispose(): void {
    if (typeof this.onCurrencyChange !== 'undefined') {
      this.onCurrencyChange.unsubscribe();
      this.onCurrencyChange = undefined;
    }
  }

  public ngOnDestroy(): void {
    this._dispose();
  }
}
